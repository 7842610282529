/* layout */
body { max-width: 800px; margin: 0 auto; padding: 0 1.6%; }

nav     { float: right; width: 33.3%; }
article { float: right; width: 66.7%; }
footer  { display: block; clear: both; }

nav h2:first-child { position: absolute !important; top: -9999px !important; left: -9999px !important; }

audio, iframe, img, object, video { max-width: 90%; max-width: calc(100% - 1.35em); }

/* czcionki */
body { font-family: sans-serif; line-height: 1.5; }

nav    { font-size: .83em; margin-top: 0.8em; line-height: 1.8; }
footer { font-size: .83em; }

/* kolory i linie */
html, body { background: white; color: black; }

a:link       { text-decoration: none; color: #E20800; }
a:visited    { text-decoration: none; color: #A02786; }
a:link:hover { text-decoration: underline; }
a.new:link { opacity: 0.5; }
a.self     { font-weight: bold; }

:focus { outline: dotted 1px; }

/* błędy i informacje */
p.error,
p.notice { padding: .34em .67em; text-align: left; }

p.error  { background: #FFF3F5; color: #C62828; border: solid 1px #FFDEE2; }
p.notice { background: #EAF5EB; color: #2E7D32; border: solid 1px #C8E6C9; }

p.error:before  { content: '\26A0\A0'; }
p.notice:before { content: '\24D8\A0'; }

p.error a:link, p.notice a:link       { color: #E20800; border-bottom: 1px dotted; text-decoration: none; }
p.error a:visited, p.notice a:visited { color: #A3007B; }

/* przyciski do skakania */
.jump       { font-weight: bold; font-size: 1.17em; margin: 0; background: inherit; }
.jump .hide { display: none; }
.jump a       { position: absolute; top: -9999px; left: -9999px; display: block; padding: 0.57em; background: inherit; outline-width: 2px; }
.jump a:focus { position: fixed;    top: 0.83em;  left: 50%; transform: translate(-50%, 4px); }

.jump#top   { position: relative; margin: 0.83em 0.57em;  }
.jump#top a { position: absolute; transform: none; }
.jump#top a:focus:first-child { top: 0; left: 0;    right: auto; }
.jump#top a:focus:last-child  { top: 0; left: auto; right: 0; }

/* menu górne */
header ul[role='menu'] { text-align: right; line-height: 2.25em; }
header ul[role='menu'] li:first-child        { float: left; font-size: 1.5em; }
header ul[role='menu'] li:first-child:after  { content: ''; }
header ul[role='menu'] li:first-child strong { font-weight: normal; }

/* FIR */
h1 a { display: block; overflow: hidden; margin: 0 auto; padding: .67em 0; line-height: 1em;
	background-position: 0%; background-size: 100%; background-repeat: no-repeat; }

/*
 * tworzenie nagłówka graficznego:
 * 
 * h1 a { background-image: url('//media.wandystan.eu/graf/naglowki/mw_heads_new.png'); width: 100%; height: 0; padding: 20.25% 0 0 0; }
 * 
 * width = szerokość obrazka / szerokość strony (typowo 800px) * 100%
 * padding = wysokość obrazka / szerokość strony * 100%
 */

/* style nagłówków i stopki */
h1, h2, h3, h4, h5, h6 { font-weight: normal; line-height: 1.2; }

h1 { font-size: 2.50em; margin: 0; }
h2 { font-size: 2.00em; margin: 0.67em 0.34em; }
h3 { font-size: 1.50em; margin: 0.75em 0.45em; }
h4 { font-size: 1.17em; margin: 0.83em 0.57em; }
h5 { font-size: 1.00em; margin: 1.12em 0.67em; }
h6 { font-size: 0.83em; margin: 1.50em 0.81em; }

/* style blokowe */
blockquote { margin: 1.12em 2.24em; }
figure, iframe, table,
dl, ol, ul, p, hr { text-align: justify; margin: 1.12em .67em; }

hr { height: 1px; background: #babdb6; border: none; }
pre { margin: 1.50em 0.81em; }

figure     { text-align: center; }
figcaption { font-size: 0.83em; }

header p, header h1,
footer p { text-align: center; }

header div.custom :last-child { margin-bottom: 0; }

/* fiksy dla wyrównania i zawijania */
div[style *= 'text-align'] * { text-align: inherit; }

div[style *= 'white-space:pre-wrap']   { white-space: normal !important; }
div[style *= 'white-space:pre-wrap'] * { white-space: pre-wrap; }

img[style *= 'float:left'] { margin: 0 .67em .67em 0; }
img[style *= 'float:right'] { margin: 0 0 .67em .67em; }

figure[style *= 'float:left'] { margin: 0 .67em .67em; }
figure[style *= 'float:right'] { margin: 0 .67em .67em; }

/* style list */
ul, ol, dd { margin-left: 2.24em; text-align: left; }
ul ul, ul ol,
ol ol, ol ul { margin-top: 0; margin-bottom: 0; }

ul { list-style: square;  }
ol { list-style: decimal; }

li > :not(blockquote):not(ul):not(ol):not(dd),
dd > :not(blockquote):not(ul):not(ol):not(dd) { margin-left: 0; margin-right: 0 }

dt { margin-top: .67em; }

ul[role='menu'],      header .custom ul { list-style: none; margin: 1.50em 0.81em; font-size: 0.83em; }
ul[role='menu'] form, header .custom ul form,
ul[role='menu'] li,   header .custom ul li { display: inline; }
ul[role='menu'] li:after,            header .custom ul li:after            { content: '\A0| '; color: #babdb6; }
ul[role='menu'] li:last-child:after, header .custom ul li:last-child:after { content: ''; }

nav ul ul ul {
	font-size: 90%;
	margin-left: 1.5em;
}

/* style drzewa zwijalnego */
li[role = 'treeitem'][aria-expanded] { list-style: none; }
li[role = 'treeitem'][aria-expanded] > span:first-child { color: #555; cursor: pointer; }
li[role = 'treeitem'][aria-expanded] > span:first-child::before { content: '▸'; display: inline-block; width: 1em; margin-left: -1em; }
li[role = 'treeitem'][aria-expanded] > ul { display: none; }

li[role = 'treeitem'][aria-expanded = 'true'] > span:first-child::before { content: '▾'; }
li[role = 'treeitem'][aria-expanded = 'true'] > ul { display: block; }

/* style tekstu */
big { font-size: 1.17em; }
small, sub, sup { font-size: 0.83em; }
code, pre { font-size: 0.83rem; }

sub { vertical-align: sub; }
sup { vertical-align: super; }

q { quotes: "„" "”" "«" "»"; }

.nobr { white-space: nowrap; }

/* styl formularzy */
form dt, form dd { margin: 0; min-height: 1.5em; }
form dt { float: left; }
form dd { margin-left: 40%; padding-left: 0; padding-right: 1.12em; }
form dd input:not([type = 'checkbox']), form dd select, form textarea { width: 100%; }

form[role="search"] { display: table; width: 100%; margin: 1.12em 0; padding: 0 0.67em; box-sizing: border-box; }
form[role="search"] p { display: table-row; }
form[role="search"] p span { display: table-cell; vertical-align: middle; }
form[role="search"] p span:last-child { width: 3.24em; padding-left: 0.5ex; }
form[role="search"] input[type="search"], form[role="search"] button { width: 100%; height: 2.24em; font: inherit; }

form[role="search"] button svg { height: 1.5em; vertical-align: middle; }
form[role="search"] button svg g { stroke: #555; stroke-width: 2px; fill: none; }

input[type = 'checkbox'],
input[type = 'radio'] { vertical-align: top; }

fieldset > :first-child { margin-top: 0; }
fieldset > :last-child { margin-bottom: 0; }
legend { margin: 0 .67em; font-weight: bold; }

/* styl tabel */
table td, table th { text-align: left; border: solid 1px #babdb6; padding: 0 .67em; }
table thead th     { text-align: center; }
table td.n         { text-align: right; font-variant: tabular-nums; }
table caption      { font-size: .83em; }
table[border = "0"] th,
table[border = "0"] td { border: none; }

/* styl przypisów */
.footnotes   { font-size: .83em; }
.footnotes p { text-align: left; }

/* porównanie wersji */
table.diff    { border-spacing: .33em; border-collapse: separate; table-layout: fixed; width: 100%; }
table.diff td { vertical-align: top; white-space: pre-wrap; font-family: monospace; }
table.diff th em { font-weight: normal; }
table.diff th,
.diffUnmodified,
.diffBlank    { border-color: transparent; }
.diffDeleted  { border-color: #FFDEE2; background: #FFF3F5; color: black; }
.diffInserted { border-color: #C8E6C9; background: #E8F5E9; color: black; }

/* ikony */
svg.icon { margin-right: 0.25em; width: 1.5em; height: 1.5em; vertical-align: -0.375em; fill: currentColor; }
svg.icon.small { width: 1.355em; height: 1.355em; vertical-align: -0.25em; }

/* mobile */
@media screen and (max-width: 520px) {
	/* layout */
	body { margin: 0 !important; }
	nav, article { float: none; width: 100%; padding: 0; }
	h1 { margin: 0 .268em; }

	/* przyciski do skakania */
	.jump a, .jump a:focus, .jump#top, .jump#top a { position: static; }

	.jump { font-size: 0.83em; margin: 1.50em 0.81em !important; text-align: center; }
	.jump a       { display: inline; padding: 0; background: transparent; outline-width: 1px; }
	.jump a:focus { transform: none; }

	.jump#top { height: 1.81em; }
	.jump#top a:first-child { float: left; }
	.jump#top a:last-child  { float: right; }

	/* menu górne */
	header ul[role='menu'] { text-align: left; }
	header ul[role='menu'] li:first-child { display: block; float: none; }

	/* styl formularzy */
	form dt { margin-top: .335em; float: none; }
	form dd { margin-left: 0; }
}
